import { AiFillHome } from "react-icons/ai";
import { PiBirdFill } from "react-icons/pi";
import { PiNotebookFill } from "react-icons/pi";
import { FaCarrot } from "react-icons/fa6";
import { FaCameraRetro } from "react-icons/fa6";
import "./Navbar.css";

const size = "25px";

export const NavbarItem = [
    {
        id: "#main",
        icon: <AiFillHome size={size}/>
    },
    {
        id: "#chicken",
        icon: <FaCameraRetro size={size}/>
        // icon: <PiBirdFill size={size}/>
    },
    {
        id: "#todo",
        icon: <PiNotebookFill size={size}/>
    },
    {
        id: "#kuu",
        icon: <FaCarrot size={size}/>
    }

]