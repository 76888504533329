import "./ChickPic.css";
import gai1 from "../svg/gai1.svg";
import gai2 from "../svg/gai2.svg";
import imageUpload from "../images/imageUploadVdo.gif";
import mapView from "../images/mapViewVdo.gif";
import { FaGithub } from "react-icons/fa";
import diagram from "../images/chickPicDiagram2.png";
import camera from "../svg/camera.svg";
import star from "../svg/star.svg";

function ChickPic() {
    return (
            <div className="flex-container" id="chicken">
                <div className="section-left">
                    <div className="grid-container">
                        <ul className="grid-item">
                            <li key={1}> 
                                 <a href={"https://github.com/mill-gai/chick-pic-frontend"} target="_blank">
                                    <FaGithub size={"25px"} color="black"/>
                                 </a>
                            </li>
                            <li key={1} style={{marginLeft:8, marginTop:4, marginRight:15}}> <div>frontend</div> </li>
                            <li key={1}>
                                <a href={"https://github.com/mill-gai/chick-pic-backend"} target="_blank">
                                    <FaGithub size={"25px"} color="black"/>
                                </a>
                            </li>
                            <li key={1} style={{marginLeft:8, marginTop:4}}> <div>backend</div> </li>
                        </ul>
                    </div>
                    <div className="star-icon star-1">
                            <object data={star} width={"22%"}> </object> 
                    </div>
                    <div className="star-icon star-2">
                            <object data={star} width={"30%"}> </object> 
                    </div>
                    <div className="star-icon star-3">
                            <object data={star} width={"25%"}> </object> 
                    </div>
                    <div className="camera-icon">
                            <object data={camera} width={"50%"}> </object> 
                    </div>
                    <h1 className="chick-pic-title">Chicken Near Me</h1>
                    <p className="description abhaya-libre-regular"> A web application dedicated to sharing photos of chickens, where users can upload images and view them on a map</p>
                    <p className="description abhaya-libre-regular"> (Jun 2024 - Present) </p>
                    {/* <div className="description abhaya-libre-regular">Architecture Diagram</div> */}
                    <div className="diagram">
                        <img style={{border: "2px solid black", width:"70%", minWidth:"300px"}} src={diagram}/>
                    </div>
                    <div className="grid-container">
                        <div className="baby-chick">
                            <object className="baby-chick-anim" data={gai2} width={"90px"}> </object>
                        </div>
                        <div className="chicken">
                            <object data={gai1} width={"110px"}> </object> 
                        </div>
                        
                    </div>
                    
            
                </div>
                <div className="section-right">
                    <div className="feature-container abhaya-libre-regular">
                        <div className="abhaya-libre-bold"> Workflow </div>
                            <ul style={{listStyleType:"circle", marginBottom: "25%"}}>
                                <li style={{width:"100%"}}>The frontend is responsible for displaying images on a map and allowing users to upload images</li>
                                <li>The API gateway receives requests from the frontend and routes them to the appropriate service</li>
                               
                            </ul>
                        <div className="abhaya-libre-bold"> Microservices </div>
                        <div className="abhaya-libre-medium" style={{textDecoration:"underline"}}>Image Service</div>
                        <ul style={{listStyleType:"circle", marginBottom: "25%"}}>
                            <li>Store and retrive image details</li>
                            <li>Images are stored in Amazon S3</li>
                            <li>Image metadata is stored in a MySQL database running in a Docker container</li>
                        </ul>
                        <div className="abhaya-libre-medium" style={{textDecoration:"underline"}}>Location Service</div>
                            <ul style={{listStyleType:"circle", marginBottom: "20%"}}>
                                <li>Provides location data</li>
                                <li>Location information is stored in a MongoDB database running in a Docker container</li>
                            </ul>
                        <div className="abhaya-libre-bold"> Future Plans </div>
                            <ul style={{listStyleType:"circle", marginBottom: "20%"}}>
                                <li style={{width:"100%"}}>Authentication</li>
                                <li>Kubernetes Deployment</li>
                            </ul>
                    </div>
                    <div className="feature-container" style={{textAlign:"center"}}>
                        <img src={imageUpload} width={"90%"} style={{border: "solid black"}}/>
                        <div className="abhaya-libre-bold"> Image Upload </div>
                    </div>
                    <div className="feature-container" style={{textAlign:"center"}}>
                        <img src={mapView} width={"90%"} style={{border: "solid black"}}/>
                        <div className="abhaya-libre-bold"> Map View </div>
                    </div>
                </div>
            </div>



        // <div style={{backgroundColor:"#f0ede6"}}>
        //     <h1>Chicken Near Me</h1>
        //     <div className="content">
        //         <div className="item">
        //             <img className="" src={mainPage} width={600}/>
        //             <div>Upload Image</div>
        //         </div>
        //         <div className="item">
        //         <img className="" src={mainPage} width={600}/>
        //         <div>View Image</div>
        //         </div>
        //     </div>
        //     <div className="row">
        //         <div className="grid-container">
        //             {/* <div class="image1">
        //                 <img className="" src={baker} />
        //             </div> */}
        //             <div className="small-gui-container">
        //                 <object className="small-gui" data={smallGui}> </object>
        //             </div>
        //             <div className="gui-container">
        //                 <object className="gui" data={gui}> </object>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default ChickPic;