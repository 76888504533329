import React from "react";
import "./VideoPlayer.css";

function VideoPlayer({ width, height, path }) {
  return (
    <iframe
      width={width}
      height={height}
      src={path}
      allowFullScreen="true"
    ></iframe>
  );
}

export default VideoPlayer;
