import "./TodoList.css";
import coil from "../svg/coil3.svg";
import VideoPlayer from "../components/VideoPlayer";
import cat from "../svg/cat2.svg";
import pencil from "../svg/pencil.svg";
import { FaGithub } from "react-icons/fa";
import diagram from "../images/todoDiagram.png";

function TodoList() {
  const TODO_LIST_PATH = "https://www.youtube.com/embed/ZWYVME40ZnU";
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  function page1() {
    return (
      <>
        <div className="notebook-content kumbh-sans">
          <h1 className="todo-list-title">Todo List:</h1>
          <ul
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              marginRight: "5%",
            }}
          >
            <li key={1}>
              <a
                href={"https://github.com/mill-gai/to-do-lists"}
                target="_blank"
              >
                <FaGithub size={"30px"} color="black" />
              </a>
            </li>
          </ul>
          <div>
            A todo list application designed to help users manage their tasks
            (May 2023 - Aug 2023)
          </div>
          <img
            style={{
              border: "2px solid black",
              width: "50%",
              minWidth: "200px",
              marginTop: "5%",
              marginBottom: "2%",
            }}
            src={diagram}
          />
          <ul style={{ listStyleType: "circle" }}>
            <li style={{ marginBottom: "2%", marginLeft: "0", width: "100%" }}>
              Users login or sign up with their phone number through Firebase
              Authentication
            </li>
            <li style={{ marginBottom: "2%", marginLeft: "0", width: "100%" }}>
              Users can view, add, delete, and update their tasks
            </li>
            <li style={{ marginLeft: "0", width: "100%" }}>
              Tasks and user details are stored in Cloud Firestore
            </li>
          </ul>
        </div>
      </>
    );
  }

  function page2() {
    return (
      <>
        <div className="notebook-content">
          <VideoPlayer width="100%" height="380" path={TODO_LIST_PATH} />
          <object className="cat" data={cat}></object>
        </div>
      </>
    );
  }

  return (
    <div className="container">
      <div className="notebook-container" id="todo">
        <object className="coil" data={coil} width={"65%"}></object>
        {!isSafari && (
          <>
            <div className="notebook page1 page-anim">{page1()}</div>
            <div className="notebook page1 pageNext">{page2()}</div>
          </>
        )}
        {isSafari && (
          <div className="notebook page1">
            <div
              style={{
                marginTop: "5%",
                width: "100%",
                height: "90%",
                overflow: "scroll",
              }}
            >
              <div>
                {page1()}
                <br></br>
                {page2()}
              </div>
            </div>
          </div>
        )}
        {/* <>
          <div className="notebook page1 page-anim">{page1()}</div>
          <div className="notebook page1 pageNext">{page2()}</div>
        </> */}
        <div className="notebook page2"></div>
        <div className="notebook page3"></div>
      </div>
      <div className="notebook-container">
        <div
          style={{
            position: "sticky",
            top: "0",
            marginTop: "90px",
            marginBottom: "40px",
          }}
        >
          <object className="pencil" data={pencil}>
            {" "}
          </object>
        </div>
      </div>
    </div>
  );
}

export default TodoList;
