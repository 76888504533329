import "./Kuu.css";
import { FaGithub } from "react-icons/fa";
import kuu from "../svg/kuu.svg";
import carrot from "../svg/carrot.svg";
import flower1 from "../svg/flower1.svg";
import flower2 from "../svg/flower2.svg";
import flower3 from "../svg/flower3.svg";
import bottle from "../svg/bottle.svg";
import diamond from "../svg/diamond.svg";
import { FaRegArrowAltCircleUp } from "react-icons/fa";

function Kuu() {
    return (
        <div className="kuu-container">
            <div className="kuu-content" id="kuu">
                <div>
                    <ul>
                        <li key={1}> 
                            <div className="kuu-title">
                                The Adventure of Kuu
                            </div>
                        </li>
                        <li key={2} style={{marginTop: "28px"}}> 
                            <a href={"https://github.com/mill-gai/project-game-the-adventure-of-kuu"} target="_blank">
                                <FaGithub size={"30px"} color="black" />
                            </a>
                        </li>
                    </ul>
                </div>
                <div>
                    <p className="kuu-description klee-one-regular">
                        A platform game developed in Unity. The objective of the game is to collect carrots while avoiding enemies <br />(Jun 2022 - Jul 2022)
                    </p>
                </div>
                <div class="button-container fredoka">
                    <a href="https://play.unity.com/mg/other/kuubuildgame" target="_blank" className="play-button"> PLAY </a>
                </div>
            </div>
            <div className="ground-object kuu">
                <object className="scale-anim" data={kuu} width={"110px"}> </object> 
            </div>
            <div className="ground-object carrot">
                <object data={carrot} width={"100px"}> </object> 
            </div>
            <div className="ground-object flower1">
                <object data={flower1} width={"120px"}> </object> 
            </div>
            <div className="ground-object flower2">
                <object data={flower2} width={"120px"}> </object> 
            </div>
            <div className="ground-object flower3">
                <object data={flower3} width={"120px"}> </object> 
            </div>
            <div className="ground">
                <a className="top klee-one-regular" href="#top">
                    <FaRegArrowAltCircleUp style={{marginRight: "5px", marginTop: "5px"}}/> Back To Top
                </a>
                <object className="bottle" data={bottle} width={"65px"}> </object>
                <object className="diamond" data={diamond} width={"80px"}> </object> 
            </div>
        </div>
    );
}

export default Kuu;