import "./Style.css";
import ChickPic from "./ChickPic";
import TodoList from "./TodoList";
import Kuu from "./Kuu";

function Main() {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return (
    <>
      <div id="main" style={{ marginTop: isSafari ? "10%" : "2%" }}>
        <div className="full-screen">
          <div className="center jetbrains-mono">
            <div style={{ fontSize: "2.5rem", fontWeight: "400" }}>Hello</div>
            <div>
              My name is Mill T. and I am a recent graduate from McGill
              University with a major in Computer Sceince. I created this
              website to showcase some of my projects. <br />
              Thanks for stopping by!
            </div>
          </div>
        </div>
        <ChickPic></ChickPic>
        <TodoList></TodoList>
        <Kuu></Kuu>
      </div>
    </>
  );
}

export default Main;
