import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Main from './pages_new/Main'

function App() {
  return (
    <>
      <Router>
        <Navbar/>
        <Routes>
          <Route path="/" Component={Main}>

          </Route>
        </Routes>
        {/* <Navbar />
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/webdev" Component={WebDev} />
          <Route path="/gamedev" Component={GameDev} />
          <Route path="/2dArt" Component={TwoDimArt} />
          <Route path="/3dArt" Component={ThreeDimArt} />
        </Routes> */}
      </Router>
    </>
  );
}

export default App;
