import React from "react";
import { NavbarItem } from "./NavbarItem";
import "./Navbar.css"

function Navbar() {
    const navbarItem = NavbarItem;
    return (
        <div className="navbar-container">
            <ul style={{paddingLeft: "0"}}>
                {navbarItem.map((item) => {
                    return (
                        <a className="navbar-icon" href={item.id}>
                            {item.icon}
                        </a>
                    );
                })}
            </ul>
        </div>
    );
}

export default Navbar;